// some useful style resets and initial form styling
@import '_normalize.scss';
@import '_essentials.scss';
@import 'components/_responsive.scss';
@import 'components/_variabel.scss';
@import 'components/_standard.scss';
@import 'components/_headfoot.scss';

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,600');

body {
    font-family: Source Sans Pro !important; 
    font-size: 17px !important;
    background: $lgrey !important;
    color: black !important;
}

.maxbreedte {
    width: $desktop;
    margin: auto;
}

#wrapper {
    overflow: hidden;
}

.breadcrumbs {
    padding: 15px 15px !important;
    font-size: 14px;
    a {
        font-size: 14px;
        color: black;
        font-weight: bold;
    }
}

/* INDEX // algemeen */

.verlopenactie .col-md-12,
.verlopenactie .kop {
    opacity: 0.5;
}

.blok.verlopenactie:after {
    content: 'verlopen';
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;;
    color: white;
    background: red;
    padding: 0 10px;
}

/*MOBILE ORDER */

@media screen and (max-width: 600px) {
    #content {display: table; width: 100%; }
    .links {display: table-footer-group; } /* Will be displayed at the bottom of the pseudo-table */
    .rechts {display: table-header-group; } /* Will be displayed at the top */
}


#content {
		.maxbreedte {
			@media screen and (max-width: 00px) {
				display: -webkit-box;
				display: -moz-box;
				display: box;

				-webkit-box-orient: horizontal;
				-moz-box-orient: horizontal;
				box-orient: horizontal;
			}
		}

    margin-top: $margintop;

        .links {     

            .widgets {
            	@media screen and (min-width: 1000px) {
                	max-width: 184px;
                }

                @media screen and (min-width: 1000px) {
	                &.scroll {
	                    position: fixed;
	                    top: 90px;
	                }
                }

                img {
                	max-width: 100%;
                    height: auto;
                }
            }

            .blok {
                background: white;
                padding: 10px 0px;
                margin-bottom: 20px;
                border: 1px solid $grey;

                .kop {
                    font-weight: bold;
                }

                ul {
                    list-style-type: none;
                    padding: 0px;
                    margin: 0px;

                    li {
                        a {
                            display: block;
                            color:  $second;
                            padding: 5px 0px;
                            border-bottom: 1px solid $lgrey;

                            &:hover {
                                font-weight: bold;
                                text-decoration: none;
                            }
                        }
                    }
                }

            }
        }

    .rechts {

        .startblok .col-md-4 {
            background: red;
        }



        .blokken {

            .intro {
                margin-bottom: $margin;

                h1 {
                    margin-top: 0px;
                    padding-top: 0px;
                    font-size: 25px;
                }

            }


            .fb {
                color: white;
            }

            .blok {
                border: 0px solid $second;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
                background: white;
                margin-bottom: $margin;

                .col-md-4, .col-md-8 {
                    padding: 0px !important;
                }

                @media screen and (max-width: 600px) {
                    padding: 0 0 10px 0 !important;
                }

                @media screen and (min-width: 600px) {
                    &.col-md-12 {
                        padding: 0px !important;
                    }
                }


                &.uitgelicht {
                    position: relative;
                    padding-bottom: 20px !important;

                    .image {
                        margin-bottom: 10px;
                    }

                    .button {
                        margin-top: 30px;
                    }

                    .bekeken {
                        margin-top: $margin;
                    }
                }

                .specs {
                	.col-md-12{
                		border-bottom: 1px solid $lgrey;
                		padding: 5px 0 !important;
                        clear: both;
                        overflow: hidden;

                			.col-md-6 {
                				padding: 0px !important;
                			}
                	}
                }

                .deactie {
	                .alignright {
					    float: right;
					    padding-left: 5px;
					    padding-right: 5px;
					}

					background: $lgrey;
					margin-top: 20px;
					padding: 20px !important;
                }

                img {
                    max-width: 95%;
                    height: auto;
                }

                .kop {
                    font-weight: bold;
                    padding: 8px 10px;
                    /* background: $lgrey; */
                    margin-bottom: 10px;
                    border-bottom: 1px solid $lgrey;
                }

                .knoppen {
                    margin: $margin/2 0px;
                    clear: both !important;
                }
            }

        }
    }

    .tussenblok {
        .tekst {
            background: white;
            border: 1px solid $grey;
            padding: 15px !important;
        }
    }
}