/* COLORS */
$main: #F19041; /*oranje*/
$second: #575556; /*donker*/
$grey: #D6D6D6;
$lgrey: #F4F3F3;
$purple: #752C7D;

/* BORDER RADIUS */
$radius: 5px;

/* FONT SIZES */
$head: 30px;
$head2: 22px;

/* MARGINS */
$margintop: 30px;
$margin: 30px;