@media screen and (max-width: $desktop) {
    #wrapper {
    	width: 100% !important;
    }

    .maxbreedte {
    	width: 90% !important;
    }
}

@media screen and (max-width: $desktop + 250px ) {
    .verkoopscan {
    	display: none;
    }
}
