/* HEADER */

.header {
    overflow: hidden;
    background: white;

    .maxbreedte {
        &.bg {
                @media screen and (max-width: 600px) {
                    background: none !important;
                }
        background-image: url('../assets/images/headerbg.jpg');
        background-repeat: no-repeat;
        background-position: right top;
        overflow: hidden;
        padding: 20px 0px;
        }
    }



    .logo {
        img {
            max-width: 250px;
            height: auto;

                @media screen and (max-width: 600px) {
                    max-width: 100% !important;
                }
        }
    }

    .zoeken {
        position: relative;
        input.zoeken {
            border: 1px solid $grey;
            border-radius: 5px;
            padding: 10px;
            margin: 0px;
            width: 80%;
            margin-top: 30px;

                @media screen and (max-width: 600px) {
                    margin-top: 10px;
                    width: 100%;
                    font-size: 13px;
                }
        }

        #searchsubmit {
            border: 0px;
            background: none;
            font-family: FontAwesome, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            color: $main;
            position: absolute;
            top: 0px;
            right: 24%;
            top: 47%;
            font-size: 20px;
                @media screen and (max-width: 600px) {
                    right: 5px;
                    top: 5px;
                }
        }

    }

    .navbar-default {
        background: none !important;

        &.navbar {
            margin-bottom: 0px !important;
            border: 0px !important;
        }
    }

    .menu {
        background: $purple;
        overflow: hidden;
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    float: left;
                    a {
                        i {
                            font-size: 25px;
                            padding-top: 2px;
                        }
                        display: block;
                        padding: 20px;
                        color: white;
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: 600;

                        &:hover {
                            background: #5D1268;
                            text-decoration: none;
                            color: white !important;
                        }
                    }
                }
            }
    }

    @media screen and (max-width: 1000px) { 

    }

    &.scroll {
        padding-bottom: 70px;
        .menu {
            position: fixed;
            top: 0px;
            z-index: 99;
            width: 100%;
        }  
    }

}

.footer {
    background: $purple;
    color: White;
    clear: both;
    overflow: hidden;
    margin-top: $margin;
    padding: $margin 0;
    text-align: center;
}