/* BUTTONS */

input, textarea {
  max-width: 100%;
}

.button {
	border-radius: $radius;
	text-align: center;
	text-transform: uppercase;
	padding: 5px;
	border: 0px;
  width: 100%;
  display: block;
  font-size: 16px;

    &.purple {

    @media screen and (max-width: 600px) {
       margin-bottom: 5px;
    }


      background: $purple !important;
      color: white;
      font-weight: bold;

        a {
          color: white;

          &:hover {
            text-decoration: none;
          }
        }
    }

		&.orange {
			background: $main;
			color: white;
			font-weight: bold;

				a {
					color: white;

					&:hover {
						text-decoration: none;
					}
				}
		}

		&.groot {
			font-size: $head2;
		}


}

/* Icon Wobble Horizontal */
@-webkit-keyframes wobble {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes wobble {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wobble {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  padding-right: 2.2em;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.wobble:before {
  content: "\f054";
  position: absolute;
  right: 1.1em;
  top: .45em;
  padding: 0 1px;
  font-family: FontAwesome;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  font-size: 15px;
}

.wgroot:before {
	font-size: 20px !important;
	top: 0.9em;

}
.wobble:hover:before, .wobble:focus:before, .wobble:active:before {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
